@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Product+Sans:400,400i,700,700i);
/* = Import GOOGLE fonts
/*-------------------------------------------------*/

/* VARIABLES ======> */
:root {
  --black: #1a1a1a;
  --red: #ff5252;
}

/*-------------------------------------------------*/
/*                RESET
/*-------------------------------------------------*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: #1a1a1a;
  background-color: var(--black);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font: 100% "Product Sans", sans-serif;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  font-size: 62.5%;
}
img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-width: 100%;
}

